<template>
  <div>
    <Header />
    <div class="side_content_wrapper row">
     <div class="col-2">
       <Sidebar />  
     </div>
      <div class="content col-10">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Sidebar from "../components/SideBar.vue";
export default {
  components: {
    Header,
    Sidebar, 
   },
};
</script>

<style>
body{
  overflow-x: hidden;
}
</style>
