<template>
  <div class="sidebar px-2">
    <h1 class="text-white">SVQT</h1>
    <ul class="list-group list-group-flush">
      <!-- <li class="list-group-item">
        <router-link class="list-group-item-in" to="/about"> Biz haqimizda </router-link>
      </li> -->
      <!-- <li class="list-group-item">
        <router-link class="list-group-item-in" to="/skill">Kursatkichlar</router-link>
      </li> -->
      <li class="list-group-item">
        <router-link class="list-group-item-in" to="/team">Jamoa</router-link>
      </li>
      <li class="list-group-item"><router-link class="list-group-item-in" to="/news"> Yangiliklar </router-link></li>
      <!-- <li class="list-group-item"><router-link class="list-group-item-in" to="/class"> Kurslar </router-link></li> -->
      <!-- <li class="list-group-item"><router-link class="list-group-item-in" to="/video"> Video </router-link></li> -->
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.sidebar {
  width: 200px;
  height: 100vh;
  background: #203647;
  position: fixed;
  top: 0;
  left: 0;
}

</style>
