<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">Navbar</a>
        <div class="d-flex">
          <h4 class="me-2">{{ role }}</h4>
          <button class="btn btn-danger" @click="logout">Chiqish</button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: "",
    };
  },
  created() {
    this.role = localStorage.getItem("role");
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
