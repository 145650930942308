import { createRouter, createWebHashHistory } from "vue-router";
import DefaultPage from "../layouts/default.vue";
import Login from "../layouts/login.vue";
import NotFound from "../layouts/404.vue";

const routes = [
  {
    path: "/",
    component: DefaultPage,
    children: [
      {
        path: "",
        name: "home",
        component: function () {
          return import("../pages/Index.vue");
        },
        meta: { auth: true },
      },
      {
        path: "/about",
        name: "about",
        component: function () {
          return import("../pages/About.vue");
        },
        meta: { auth: true },
      },
      {
        path: "/video",
        name: "video",
        component: function () {
          return import("../pages/Video.vue");
        },
        meta: { auth: true },
      },
      {
        path: "/news",
        name: "news",
        component: function () {
          return import("../pages/News.vue");
        },
        meta: { auth: true },
      },
      {
        path: "/skill",
        name: "skill",
        component: function () {
          return import("../pages/Skill.vue");
        },
        meta: { auth: true },
      },

      {
        path:"/class",
        name:"class",
        component: function() {
          return import('../pages/Class.vue')
        }
      },
      
      {
        path: "/team",
        name: "team",
        component: function () {
          return import("../pages/Team.vue");
        },
        meta: { auth: true },
      },
      
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/404", 
    name: "404",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const reqAuth = to.meta.auth;
  const token = localStorage.getItem("token");

  if (reqAuth && token) {
    if (to.name == "login") {
      next({ name: "home" });
      return;
    }

    next();
    return;
  }

  if (token) {
    if (to.name == "login") {
      next({ name: "home" });
      return;
    }
  } else {
    if (to.name != "login") {
      next({ name: "login" });
      return;
    }
  }

  next();
});

export default router;
